<template>
  <div class="mt-auto mb-auto">
    <h3>New password</h3>
    <p class="mt-6">Enter new password</p>
    <form class="mt-8" @submit.prevent="submitNewPassword">
      <div class="input-field">
        <label for="password">Password</label>
        <input
          class="focus:outline-none"
          id="password"
          :type="notEyePassword ? 'password' : 'text'"
          placeholder="Password"
          autocomplete="off"
          required
          v-model.trim="password"
        >
        <i
          :class="[ notEyePassword ? 'icon-not-eye' : 'icon-eye']"
          @click="notEyePassword = !notEyePassword"
        ></i>
      </div>
      <div class="input-field">
        <label for="repeatPassword">Repeat password</label>
        <input
          class="focus:outline-none"
          id="repeatPassword"
          :type="notEyeRepeat ? 'password' : 'text'"
          placeholder="Repeat password"
          autocomplete="new-password"
          required
          v-model.trim="repeatPassword"
        >
        <i
          :class="[ notEyeRepeat ? 'icon-not-eye' : 'icon-eye']"
          @click="notEyeRepeat = !notEyeRepeat"
        ></i>
      </div>
      <div class="forgot flex mb-4 mt-4">
        <div class="ml-auto">
          Remember your password?
          <span class="cursor-pointer text-white ml-1"
                @click="$router.push('/login')">Log In</span>
        </div>
      </div>
      <p v-if="errorMessage || errMsg" class="err-msg">{{ errorMessage || errMsg }}</p>
      <button type="submit" title="Save password" class="btn btn-yellow">Save password</button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'NewPassword',
  data: () => ({
    password: '',
    repeatPassword: '',
    notEyePassword: true,
    notEyeRepeat: true,
    errorMessage: '',
    disabled: false
  }),
  computed: {
    errMsg () {
      return this.$store.state.auth.errMsg
    }
  },
  methods: {
    submitNewPassword () {
      this.errorMessage = ''
      this.disabled = false
      if (this.password !== this.repeatPassword || this.errMsg) {
        this.disabled = true
        this.errorMessage = this.errMsg ? '' : 'Passwords does not match'
      }
      if (!this.disabled) {
        this.disabled = true
        this.$emit('submitNewPassword', this.password)
      }
    }
  }
}
</script>
