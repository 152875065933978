<template>
  <div class="mt-auto mb-auto">
    <h3>Forgot password?</h3>
    <p class="mt-6">A code will be sent to user’s email or phone</p>
    <form class="mt-8" @submit.prevent="submitForgot">
      <div class="input-field">
        <label for="name">Email / Phone</label>
        <input
          class="focus:outline-none"
          id="name"
          type="text"
          placeholder="Your email or phone"
          required
          v-model.trim="name"
        >
      </div>
      <div class="forgot flex mb-4 mt-4">
        <div class="ml-auto">
          Remember your password?
          <span class="cursor-pointer text-white ml-1"
                @click="$router.push('/login')">Log In</span>
        </div>
      </div>
      <p v-if="errMsg" class="err-msg">{{ errMsg }}</p>
      <button type="submit" title="Continue" class="btn btn-yellow">Continue</button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'ForgotForm',
  data: () => ({
    name: '',
    disabled: false
  }),
  computed: {
    errMsg () {
      return this.$store.state.auth.errMsg
    }
  },
  methods: {
    submitForgot () {
      if (this.errMsg) this.disabled = false
      if (!this.disabled) {
        this.disabled = true
        this.$emit('submitForgot', this.name)
      }
    }
  }
}
</script>
