<template>
  <component
    :is="forgetPasswordComponent"
    title="Forgot Password"
    buttonName="Continue"
    @submitForgot="forgetPassword"
    @submitCode="handleSubmitCode"
    @submitNewPassword="setNewPassword"
  />
</template>

<script>
import ForgotForm from '@/components/Login/ForgotForm'
import SecurityCode from '@/components/Registration/SecurityCode'
import NewPassword from '@/components/Login/NewPassword'

export default {
  name: 'ForgotPassword',
  components: {
    ForgotForm,
    SecurityCode,
    NewPassword
  },
  data: () => ({
    forgetPasswordPage: 'ForgotForm'
  }),
  computed: {
    forgetPasswordComponent () {
      const currenPage = this.$store.state.auth.securityPage
      if (currenPage === 'SecurityCode') return 'SecurityCode'
      if (currenPage === 'NewPassword') return 'NewPassword'
      return 'ForgotForm'
    }
  },
  methods: {
    forgetPassword (email) {
      this.$store.dispatch('forgetPassword', email)
    },
    handleSubmitCode (code) {
      this.$store.dispatch('verifyCodeForgetPassword', code)
    },
    setNewPassword (password) {
      this.$store.dispatch('setNewPassword', password)
    }
  }
}
</script>
